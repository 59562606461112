.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: hsl(193, 10%, 25%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.overlay5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: hsl(193, 10%, 25%, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modal {
  background-color: hsla(193, 40%, 10%);
  max-width: 94%;
  width: 650px;
  min-height: 100px;
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px hsla(0, 0%, 0%, 0.5);
  padding: 20px;
  color: #eef;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}

.modal_header h2 {
  font-size: 18px;
  font-weight: 500;
  color: #eef;
}

.close {
  width: 30px;
  height: 20px;
}

.close:hover {
  cursor: pointer;
  /* background-color: rgb(38, 35, 68); */
}

.close svg {
  width: 30px;
  height: 30px;
  stroke-width: 1px;
}

/**********************  BODY ***************************/

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.modal_field {
  /* background-color: rgba(255, 255, 255, 0.157); */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal_field label {
  font-size: 18px;
  font-weight: 500;
  color: #eef;
}

.modal_field input {
  border-radius: 4px;
  padding: 10px;
  font-size: 1.2rem;
  color: #eef;
  outline: none;
  border: 2px solid hsla(0, 0%, 100%, 0.5) !important;
  background-color: transparent;
}

.modal_body button {
  background-color: hsl(193, 65%, 45%);
  font-size: 18px;
  padding: 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #eef;
}
