* {
  box-sizing: border-box;
  font-family: system-ui;
  margin: 0;
  /* outline: 1px solid red; */
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: hsl(193, 10%, 25%);
}

body::-webkit-scrollbar-thumb {
  background-color: hsl(193, 30%, 50%);
}


.tab-bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  color: #dadada;
}

.tabs {
  background-color: hsl(193, 10%, 20%);
  color: #cfcfcf;
}

.content {
  background-color: hsl(193, 10%, 25%);
  max-width: 100%;
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.5em;
}

.btn {
  text-decoration: none;
  background-color: hsl(200, 65%, 50%);
  color: #efefef;
  padding: 10px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.btn:active {
  background-color: hsl(200, 50%, 55%);
}

.options {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 40px;
  margin-bottom: 30px;
  margin-top: 50px;
  width: 100%;
  font-size: 1.4rem;
}

input.option {
  display: none;
}

input.option:checked + label {
  background-color: rgb(22, 220, 255);
  padding: 10px;
  border-radius: 4px;
}

.block-reload {
  display: flex;
  /* background-color: rgba(255, 255, 255, 0.123); */
  width: 100%;
  justify-content: space-between;
  height: 35px;
  padding: 0 10px;
}

.spin {
  cursor: pointer;
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(-360deg);
  }
}

.color-admin {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

input[type="text"],
input[type="password"] {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 18px;
  color: #cfcfcf;
  outline: none;
  cursor: text;
}


tr:nth-child(2n) {
  background-color: hsl(193, 5%, 28%);
}

@media screen and (max-width: 600px) {
  .options {
    grid-template-columns: repeat(4, auto);
    gap: 10px;
    font-size: 1rem;
  }
}
